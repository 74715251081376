<template>
  <div class="custom-employee-search-list">
    <template v-if="localUsersObject">
      <esmp-select
        class="custom-employee-search-list__select"
        ref="select"
        placeholder="Поиск сотрудников"
        filterable
        :loading="searchLoading"
        :remote-method="debouncedSearchUsers"
        @on-select="onSelect"
      >
        <esmp-select-option
          v-for="employee in employeeList"
          :key="employee.login"
          :value="employee"
          class="custom-employee-search-list__select-option"
        >
          <span class="custom-employee-search-list__select-option-fio">
            {{ employee.fullName }}
          </span>
          <span class="custom-employee-search-list__select-option-email">
            {{ employee.email }}
          </span>
        </esmp-select-option>
      </esmp-select>
      <esmp-table-wrapper
        v-if="localUsersObjectHasValues"
        title="Выбранные сотрудники"
        :is-allow-full-page="false"
        :is-column-settings-button-showed="false"
        class="custom-employee-search-list__table"
      >
        <esmp-table
          :columns="columns"
          :rows="localUsersObject.values"
          stripe
        >
          <template #cell-email="{ tr }">
            {{ tr.extendedValues[0].value }}
          </template>
          <template #cell-actions="{ tr }">
            <span class="custom-employee-search-list__action" @click="removeUser(tr.sortOrder)">
              <esmp-icon name="trash" />
            </span>
          </template>
        </esmp-table>
      </esmp-table-wrapper>
    </template>
    <template v-else>
      Ошибка. Не удалось получить данные. Обратитесь к администратору.
    </template>
  </div>
</template>

<script>
import debounce from 'lodash/debounce';
import { SEARCH_DELAY } from '@/constants/search';

export default {
  name: 'CustomEmployeeSearchList',
  model: {
    prop: 'usersObject',
    event: 'change',
  },
  props: {
    usersObject: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      columns: [
        {
          title: 'Имя сотрудника',
          key: 'name',
        },
        {
          title: 'Email',
          key: 'email',
        },
        {
          title: '',
          key: 'actions',
        },
      ],
      employeeList: [],
      searchLoading: false,
    };
  },
  computed: {
    localUsersObject: {
      get() {
        return this.usersObject;
      },
      set(data) {
        this.$emit('change', data);
      },
    },
    localUsersObjectHasValues() {
      return this.localUsersObject.values?.length;
    },
  },
  methods: {
    onSelect(user) {
      const selected = user.value;
      const lastOrder = this.localUsersObjectHasValues
        ? Math.max(
          ...this.localUsersObject.values.map((el) => Number(el.sortOrder)),
        )
        : 0;
      if (!this.localUsersObjectHasValues) {
        this.$set(this.localUsersObject, 'values', []);
      }
      this.$set(this.localUsersObject, 'values', [...this.localUsersObject.values, {
        /*
         * TODO это сделано намеренно, во избежания конфликтов с esmp-table
         * Надо видимо поправить генерацию айдишников внутри таблицы
         */
        id: undefined,
        name: selected.fullName,
        sortOrder: lastOrder + 1,
        extendedValues: [
          {
            value: selected.email,
            type: 'email',
          },
        ],
      }]);
      this.$refs.select.setQuery(null);
      this.employeeList = [];
    },
    searchUsers(query) {
      this.searchLoading = true;
      if (query && query.length > 2) {
        this.$API.user.search({ query }).then(({ data }) => {
          this.employeeList = data;
        }).finally(() => {
          this.searchLoading = false;
        });
      } else {
        this.employeeList = [];
        this.searchLoading = false;
      }
    },
    removeUser(sortOrder) {
      this.$set(
        this.localUsersObject, 'values', this.localUsersObject.values.filter((item) => item.sortOrder !== sortOrder),
      );
    },
  },
  created() {
    this.debouncedSearchUsers = debounce(this.searchUsers, SEARCH_DELAY);
  },
};
</script>
<style lang="scss">
.custom-employee-search-list {
  .esmp-table col {
    width: auto!important;
  }
  &__table {
    margin: 10px 0 0 0;
  }
  &__action {
    cursor: pointer;
    color: $color-grayscale-60;
    &:hover {
      color: $color-black;
    }
  }
  &__select-option {
    &-fio {
      margin-right: 16px;
      font-size: 14px;
      line-height: 20px;
      color: $color-black;
      transition: color .15s;
    }
    &-email {
      font-size: 12px;
      line-height: 16px;
      color: $color-black-op-50;
      margin-right: 16px;
    }
    &:hover &-fio {
      color: $color-primary-1-night;
    }
  }
}
</style>
